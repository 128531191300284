<template>
    <div class="patientInfoParentContainer">
        <v-card class="patientInfocardStyle" flat>
            <div class="detailsStyle">
                <p>Name</p>
                <h5 style="font-weight: 500;">{{ patientDetails.name }}</h5>
            </div>
            <div class="border-style"></div>
            <div class="detailsStyle">
                <p>Age</p>
                <h5 style="font-weight: 500;">{{ calculateAge(patientDetails) }}</h5>
            </div>
            <div class="border-style"></div>
            <div class="detailsStyle">
                <p>Gender</p>
                <h5 style="font-weight: 500;">{{ patientDetails.gender }}</h5>
            </div>
            <div class="border-style"></div>
            <div class="detailsStyle">
                <p>comments</p>
                <h5 style="font-weight: 500;">{{ description }}</h5>
            </div>
        </v-card>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    name: 'patientInfo',
    props:['pageType','patientDetailsProps','descriptionProps'],
    data() {
        return {
            patientDetails:{},
            description:''
        }
    },
    methods: {
        calculateAge(patientDetails) {
            if(!patientDetails && !patientDetails.dob) return '--'
            const now = moment();
            const birthDate = moment(patientDetails.dob);
            return now.diff(birthDate,'years');
        }
    },
    mounted() {
        if(this.$props.pageType === 'VIEW'){
            this.patientDetails = this.$props.patientDetailsProps
            this.description = this.$props.descriptionProps
            return
        }
        this.patientDetails = this.$store.state.nurseBooking.patientDetails
        this.description = this.$store.state.nurseBooking.description
    }
}
</script>
<style scoped>
.patientInfocardStyle {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #E0E0E0 !important;
}

.detailsStyle {
    display: flex;
    flex-direction: row;
    padding-bottom: 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
}
.detailsStyle p{
    font-size: 13px;
    color: #828282 !important;
}

.border-style {
    width: 100%;
    border-bottom: 1px solid #F2F2F2 !important;
}

.patientInfoParentContainer{
    width: 100%
}

p{
    margin: 0;
}
</style>