<template>
    <v-card flat :class="activeAppointment ? 'activeCardStyle' : 'cardStyle'" @click="viewBookingDetails">
        <div :class="activeAppointment ? 'activeParentClass' : 'parentClass'">
            <div class="NurseBookingIDDetails" v-if="activeAppointment">
                <!-- <div style="display: flex;flex-direction: column; align-items: flex-start">
                    <span>Nurse Status</span>
                    <v-chip v-if="appointmentDetail.display_status === 'ACTIVE'"  color="rgba(39, 174, 96, 0.12)" label
                        style="color: #27AE60 !important; font-size: 12px !important; font-weight: 600;">Yet To start</v-chip>
                    <v-chip  v-else-if="appointmentDetail.display_status === 'COMPLETED'"  color="#fff" label
                        style="color: #1467BF !important; font-size: 12px !important; font-weight: 600;">completed</v-chip>
                </div> -->
                <div class="nurseDetails">
                    <span>Booking Id</span>
                    <h5>{{ `#${appointmentDetail.pk}` }}</h5>
                </div>
            </div>
            <v-spacer v-if="activeAppointment"></v-spacer>
            <div class="NurseBookingIDDetails">
                <div class="nurseInfoContainer">
                    <img style="margin-top: 4px;"
                        src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/commonNurseIcon.svg" />
                    <div class="nurseDetails">
                        <div v-if="appointmentDetail.nurse_name === 'UNALLOCATED'" style="display: flex; flex-direction: column;">
                            <div v-if="appointmentDetail.appointment_status=='CANCELLED'">
                                <v-chip label color="#F0F8FE" style="color:  #48ACEF !important; font-size: 12px !important; font-weight: 600;">{{ "Appointment Cancelled" }}</v-chip>
                            </div>
                            <div v-else>
                                <v-chip label color="#F0F8FE" style="color:  #48ACEF !important; font-size: 12px !important; font-weight: 600;">{{ "Yet to be allocated" }}</v-chip>
                                <p class="body-text ma-0 mt-2">{{ 'Allotting takes within 24 hrs' }}</p>
                            </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column;">
                            <h5>{{ appointmentDetail.nurse_name }}</h5>
                            <span>{{ appointmentDetail.nurseDisplayStatus }}</span>
                        </div>
                    </div>
                </div>
                <div class="bookingIDContainer" v-if="!activeAppointment && !completedAppointment && !viewDetails">
                    <span>Booking Id</span>
                    <h5>{{ `#${appointmentDetail.pk}` }}</h5>
                </div>
                <div class="bookingIDContainer" v-if="false">
                    <v-btn depressed color="#48ACEF"
                        style="color: #fff !important; text-transform: none; height: 32px !important; padding: 0 8px !important;">
                        Re-Book
                    </v-btn>
                </div>
                <div class="bookingIDContainer" v-if="viewDetails && !activeAppointment && appointmentDetail.nurse_name !== 'UNALLOCATED'">
                    <v-btn depressed color="#48ACEF" @click="viewNurseDetails()"
                        style="color: #fff !important; text-transform: none; height: 32px !important; padding: 0 8px !important;">
                        View
                    </v-btn>
                </div>
            </div>
            <div class="dateShiftStyle">
                <div class="commonDisplayContainer">
                    <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/calendar.svg" />
                    <span>{{ `${formatDate(bookingStartDate)} - ${formatDate(bookingEndDate)}` }}</span>
                </div>
                <div class="commonDisplayContainer">
                    <img style="width: 24px; height: 24px;"
                        :src="(appointmentDetail.shift_type === 'MORNING') ? 'https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/morningShiftIcon.svg' : 'https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/nightShiftIcon.svg'" />
                    <span>{{ `${appointmentDetail.shift_type.toLowerCase()} shift` }}</span>
                </div>
            </div>
            <div v-if="false" style="width: 100%;" class="ratingsContainer">
                <div style="border-top: 1px solid #E0E0E0;"></div>
                <p>Rate overall experience</p>
                <div>
                    <v-rating v-model="ratings">
                        <template #item="{ index }">
                            <img :src="index < ratings ? activeStarImageUrl : defaultStarImageUrl"
                                @click="ratingsHandler(index + 1)">
                        </template>
                    </v-rating>
                </div>
            </div>
        </div>
    </v-card>
</template>
<script>
export default {
    name: 'appointmentsCard',
    props: ['appointmentDetail', 'appointmentType'],
    data() {
        return {
            activeAppointment: false,
            completedAppointment: false,
            ratings: 3,
            activeStarImageUrl: "https://d3vsnl8idgwrlw.cloudfront.net/adminNurseActiveStarIcon.svg",
            defaultStarImageUrl: "https://d3vsnl8idgwrlw.cloudfront.net/adminNurseDefaultStarIcon.svg",
            shiftType: 'Morning Shift',
            monthNames: ["JAN", "FEB", "MAR", "APR", "May", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"],
            bookingStartDate: '',
            bookingEndDate: '',
            viewDetails: false,
            bgColor: '#fff',
        }
    },
    methods: {
        ratingsHandler(ratings) {
            ratings = ratings
        },
        formatDate(date) {
            const dateObj = new Date(date);
            const day = dateObj.getUTCDate();
            const month = this.monthNames[dateObj.getUTCMonth()];
            return `${day} ${month}`;
        },
        viewBookingDetails() {
            if(this.$props.appointmentType === 'VIEW'){
                return
            }
            let bookingID = this.$props.appointmentDetail.pk
            this.$router.push({
                path: `/bookingDetails/${bookingID}`
            })
        },
        viewNurseDetails(){
            if(this.$props.appointmentDetail.nurse_ID === 'UNALLOCATED'){
                return
            }
            let nurseID = this.$props.appointmentDetail.nurse_ID
            this.$router.push({
                path: `/nurseDetails/${nurseID}`
            })
        }
    },
    mounted() {
        this.activeAppointment = (this.$props.appointmentType === 'ACTIVE') ? true : false;
        this.completedAppointment = (this.$props.appointmentType === 'COMPLETED') ? true : false;
        this.viewDetails = (this.$props.appointmentType === 'VIEW') ? true : false;
        this.bgColor = this.viewDetails ? "#F0F8FE" : "#fff"
        let splittedDateValues = this.$props.appointmentDetail.appointment_date_range.split('#');
        this.bookingStartDate = splittedDateValues[0];
        this.bookingEndDate = splittedDateValues[1];
    }
}
</script>
<style scoped>
.nurseInfoContainer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

span {
    color: #828282 !important;
    font-size: 12px;
}

.nurseDetails {
    text-align: left;
    padding-bottom: .5rem;
}

.bookingIDContainer {
    text-align: left;
    padding-bottom: .5rem;
}

.NurseBookingIDDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.dateShiftStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.commonDisplayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.parentClass {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.cardStyle {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    border-radius: 8px;
    background-color: v-bind('bgColor') !important;
    border: solid 1px #E0E0E0 !important; 
    margin-bottom: 12px;
}

.activeCardStyle {
    border-radius: 6px;
    border-left: 6px solid #48ACEF !important;
    background: #F0F8FE !important;
    display: flex;
    padding: 16px 20px 20px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.activeParentClass {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.ratingsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #E0E0E0 !important;
}

.ratingsContainer p {
    color: #333 !important;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 10px;
}
</style>