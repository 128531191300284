<template>
    <div class="contactDetailsParentClass">
        <v-card class="patientContactCardStyle" flat>
            <div class="addressDetailsStyle">
                <p>Phone</p>
                <h5>{{ addressDetails.mobile_number }}</h5>
            </div>
            <div class="border-style"></div>
            <div class="addressDetailsStyle">
                <p>Street Address</p>
                <h5>{{ `${addressDetails.house_number}, ${addressDetails.street_address}` }}</h5>
            </div>
            <div class="border-style"></div>
            <div class="addressDetailsStyle">
                <p>Landmark</p>
                <h5>{{ `${addressDetails.landmark}` }}</h5>
            </div>
            <div class="border-style"></div>
            <div class="addressDetailsStyle">
                <p>Township</p>
                <h5>{{ `${addressDetails.township}` }}</h5>
            </div>
        </v-card>
    </div>
</template>
<script>
export default {
    name: 'patientContact',
    props:['pageType','addressDetailsProps','townShipName'],
    data() {
        return {
            addressDetails:{}
        }
    },
    mounted(){
        if(this.$props.pageType === "VIEW"){
            this.addressDetails = this.$props.addressDetailsProps
            return
        }
        this.addressDetails = this.$store.state.nurseBooking.addressDetails
    }   
}
</script>
<style scoped>
.patientContactCardStyle {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #E0E0E0 !important;
}

.addressDetailsStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.addressDetailsStyle p{
    color: #828282 !important;
    font-size: 14px;
}
.contactDetailsParentClass{
    width: 100%;
}
.border-style {
    width: 100%;
    border-bottom: 1px solid #F2F2F2 !important;
}

.addressDetailsStyle h5{
    font-weight: 500;
}

p{
    margin: 0;
}
</style>