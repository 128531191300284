<template>
    <v-app class="desktopLayoutClass">
        <header class="headingContainer">
            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/back_button.svg" style="cursor: pointer;" @click="takeMeBack()"/>
            <h3>Nurse Booking</h3>
        </header>
        <div v-if="loader">
          <v-skeleton-loader class="mx-auto" elevation="0" max-width="400" type="table-heading, list-item-two-line, image, table-tfoot" />
        </div>
        <div v-else class="parentClass">
            <div class="statusIDContainer">
                <div style="display: flex;flex-direction: column; align-items: flex-start">
                    <span>Nurse Status</span>
                    <v-chip v-if="appointmentDetail.appointment_status === 'CANCELLED'"  color="#fff" label
                        style="color: #EB5757 !important; font-size: 12px !important; font-weight: 600;">CANCELLED</v-chip>
                    <v-chip v-else-if="appointmentDetail.nurse_name=='UNALLOCATED'" color="#fff" label
                        style="color: #48ACEF !important; font-size: 12px !important; font-weight: 600;">UNALLOCATED</v-chip>
                    <!-- <div v-if="currentDateDetails && appointmentDetail.nurse_name!='UNALLOCATED'"> -->
                        <v-chip v-else-if="curentDateDetails && curentDateDetails.nurse_status === 0" color="#27ae601f" label
                        style="color: #27AE60 !important; font-size: 12px !important; font-weight: 600;">Yet to Start</v-chip>
                        <v-chip v-else-if="curentDateDetails && curentDateDetails.nurse_status === 1" color="#27ae601f" label
                            style="color: #27AE60 !important; font-size: 12px !important; font-weight: 600;">On the way</v-chip>
                        <v-chip v-else-if="curentDateDetails && curentDateDetails.nurse_status === 2" color="#27ae601f" label
                            style="color: #27AE60 !important; font-size: 12px !important; font-weight: 600;">Reached</v-chip>
                        <v-chip v-else-if="curentDateDetails && curentDateDetails.nurse_status === 3" color="#27ae601f" label
                            style="color: #27AE60 !important; font-size: 12px !important; font-weight: 600;">In progress</v-chip>
                        <v-chip v-else-if="curentDateDetails && curentDateDetails.nurse_status === 4"  color="#fff" label
                            style="color: #1467BF !important; font-size: 12px !important; font-weight: 600;">COMPLETED</v-chip>
                    <!-- </div> -->
                    <v-chip v-else color="#fff" label
                        style="color: #48ACEF !important; font-size: 12px !important; font-weight: 600;">-</v-chip>
                </div>
                <div class="bookingIDContainer">
                    <span>Booking Id</span>
                    <h5>{{ `#${appointmentDetail.pk}` }}</h5>
                </div>
            </div>
            <v-card flat class="otpContainer" v-if="otpDetails && otpDetails.reachCode && otpDetails.completeCode">
                <div class="reachCodeContainer">
                    <v-chip color="#47acef1f"
                        style="color: #1467BF !important; font-size: 12px !important; font-weight: 600; width: 143.5px;">
                        <v-avatar left style="margin-right: 8px;">
                            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/reachCodeIcon.svg" />
                        </v-avatar>
                        Reach Code
                    </v-chip>
                    <v-chip color="#fff" label style="font-size: 12px !important; font-weight: 600;">
                        <div class="d-flex justify-space-between" style="width: 120.5px;">
                            <P style="font-size: 16px; font-weight: 600; color: #333;" class="ma-0">{{
                                otpDetails.reachCode }}</P>
                            <!-- <v-avatar right style="margin-left: 1rem; padding: 4px; "></v-avatar> -->
                            <!-- <img style="width: 16px; height: auto;"
                                src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/copy-icon.svg" /> -->
                        </div>
                    </v-chip>
                </div>
                <div class="reachCodeContainer">
                    <v-chip color="#27ae601f"
                        style="color: #27AE60 !important; font-size: 12px !important; font-weight: 600; width: 143.5px;">
                        <v-avatar left style="margin-right: 8px;">
                            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/success_tick.svg" />
                        </v-avatar>
                        Complete Code
                    </v-chip>
                    <v-chip color="#fff" label style="font-size: 12px !important; font-weight: 600;">
                        <div class="d-flex justify-space-between" style="width: 120.5px;">
                            <P style="font-size: 16px; font-weight: 600; color: #333;" class="ma-0">{{
                                otpDetails.completeCode }}</P>
                            <!-- <v-avatar right style="margin-left: 1rem; padding: 4px; "></v-avatar> -->
                            <!-- <img style="width: 16px; height: auto;"
                                src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/copy-icon.svg" /> -->
                        </div>
                    </v-chip>
                </div>
            </v-card>

            <div>
                <h5>Appointment Details</h5>
                <appointmentsCard appointmentType="VIEW" :appointmentDetail="appointmentDetail"></appointmentsCard>
            </div>
            <div>
                <h5>Patient Info</h5>
                <PatientInfo pageType="VIEW" :patientDetailsProps="appointmentDetail.patientDetails"
                    :descriptionProps="appointmentDetail.description"></PatientInfo>
            </div>
            <div>
                <h5>Patient Contact</h5>
                <ContactDetails pageType="VIEW" :addressDetailsProps="appointmentDetail.addressDetails"></ContactDetails>
            </div>
            <div 
                v-if="appointmentDetail.display_status === 'ACTIVE'"
            >
                <h5>Attachments</h5>
                <ReportPDFCard :attachments="appointmentDetail.attachments"/>
                <UploadAttachments v-if="appointmentDetail.attachments.length < 3" :bookingID="appointmentDetail.sk" :leftAttachmentCount="3-appointmentDetail.attachments.length"/>
            </div>
            <!-- less than 24hrs, dont display Cancel button -->
            <div v-if="dateMoreThan24Hours && appointmentDetail.appointment_status=='CONFIRMED'">
                <v-btn 
                    v-if="appointmentDetail.display_status === 'ACTIVE'" 
                    outlined color="#EB5757" style="text-transform: none; width: 100%;"
                    @click="cancelBooking()"
                >Cancel Booking</v-btn>
            </div>
        </div>
        <v-card flat class="reBookingContainer" v-if="false">
            <v-btn tonal color="#48ACEF" style="text-transform: none; width: 100%; color: #fff;">Re-Book Nurse</v-btn>
        </v-card>
    </v-app>
</template>
<script>
import appointmentsCard from '../../components/nurseBookingComponents/appointmentsCard.vue';
import ContactDetails from '../../components/nurseBookingComponents/contactDetails.vue';
import PatientInfo from '../../components/nurseBookingComponents/patientInfo.vue';
import ReportPDFCard from '../../components/nurseBookingComponents/reportPDFCard.vue';
import UploadAttachments from '../../components/nurseBookingComponents/uploadAttachments.vue';
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
import { mixinFunctions } from '../../mixins/index'
export default {
    name: 'bookingDetails',
    mixins:[mixinFunctions],
    components: {
        appointmentsCard,
        PatientInfo,
        ContactDetails,
        ReportPDFCard,
        UploadAttachments
    },
    data() {
        return {
            appointmentDetail: {},
            otpDetails: {},
            curentDateDetails: {},
            appointmentStartDate: '',
            dateMoreThan24Hours: false,
            loader: false,
        }
    },
    methods:{
        takeMeBack(){
            this.$router.go(-1);
        },
        cancelBooking() {
            this.$router.push({
                path: "/nurseBooking/cancellation/" + this.appointmentDetail.pk
            });
        },
        checkIfDateMoreThan24Hours(targetDate, shift_type) {
            let targetDateTime = new Date(
                parseInt(targetDate.substring(0, 4)),       // Year
                parseInt(targetDate.substring(4, 6)) - 1,   // Month (months are zero-based)
                parseInt(targetDate.substring(6, 8)),       // Day
            );

            let currentDate = new Date();

            if (shift_type === 'NIGHT')
                targetDateTime.setHours(targetDateTime.getHours() + 19);
            else 
                targetDateTime.setHours(targetDateTime.getHours() + 7);
            
            // Calculate the time difference in milliseconds
            let timeDifference = targetDateTime - currentDate;

            // Check if the absolute difference is less than 24 hours (86400000 milliseconds)
            if (timeDifference < 86400000) {
                this.dateMoreThan24Hours = false;
            } else {
                this.dateMoreThan24Hours = true;
            }
        },
        copyToClipBoard(code){
            navigator.clipboard.writeText(code);
            console.log('clicked')
        }
    },
    async mounted() {
        try {
            this.loader = true;
            let bookingID = this.$route.params['bookingID'];
            let fetchBookingDetailsResponse = await axios_auth_instance_nurseBooking.get(`/pagewise/bookingDetails?bookingID=${bookingID}`);
            this.otpDetails = fetchBookingDetailsResponse.data.otpDetails;
            this.appointmentDetail = fetchBookingDetailsResponse.data.data;
            this.appointmentDetail.addressDetails = {
                ...this.appointmentDetail.addressDetails,
                township: this.appointmentDetail.township
            }
            this.curentDateDetails = (fetchBookingDetailsResponse.data.currentDateDetails) ? (fetchBookingDetailsResponse.data.currentDateDetails) : null;

            this.appointmentStartDate = this.appointmentDetail.date_shift.toString().slice(0, -1);

            this.checkIfDateMoreThan24Hours(this.appointmentStartDate, this.appointmentDetail.shift_type);

            this.loader = false;
        } catch (error) {
            console.log('[ERROR][BookingDetailsPage] : ', error);
            if (error.response.status === 401) {
                this.$cookies.remove('customerToken');
                this.$router.push({
                    name: "Login",
                });
            }
        }
        this.userPageViewTable('NBAAppointmentDetailPage');
    }
}
</script>
<style scoped>
.statusIDContainer {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #F4F6FA;
}

.bookingIDContainer {
    text-align: left;
    /* padding-bottom: .5rem; */
}

span {
    color: #828282 !important;
    font-size: 12px;
}

.otpContainer {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    border-radius: 8px;
    background-color: #e9f4ffcc !important;
}

.reachCodeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.parentClass {
    display: flex;
    flex-direction: column;
    padding: 10px 12px  80px 12px;
    text-align: left;
    gap: 32px;
}

h5 {
    margin-bottom: 12px;
}

.headingContainer {
    display: flex;
    width: 100%;
    padding: 12px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #E0E0E0 !important;
    background-color: #fff !important;
}

.reBookingContainer{
    position: fixed;
    padding: 16px;
    bottom: 0; 
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #E0E0E0;
}
</style>