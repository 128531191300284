<template> 
    <div
        class="mb-8"
        style="overflow-y: hidden;"
    >
        <div class="pdfShowClass">
            <div
                v-for="item in attachments"
                :key="item.s3UploadFileName"
                class="imageBox"
            >
                <img
                    class="ml-5"
                    style="display: flex; flex-direction: column; align-items: center; gap: 4px;" 
                    :src="calculateSRCURL(item.fileType)"
                >
                <p>{{ item.userOPTFileName }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ReportPDFCard',
    props: {
        attachments: {
            type: Array,
            required: true,
            default: () => ([]),
        },
    },
    data() {
        return {
            skeletonLoaderFlag: false,
        };
    },
    async mounted() {
    },
    methods: {
        calculateSRCURL(fileType) {
            if (fileType === 'PDF') {
                return 'https://d3vsnl8idgwrlw.cloudfront.net/pdfIcon.svg';
            } else if (fileType === 'IMAGE') {
                return 'https://d3vsnl8idgwrlw.cloudfront.net/imageIcon.svg';
            }
        },
    },
};
</script>
<style scoped>
.imageBox {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 11px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--Gray-5, #E0E0E0);
    background: #FFF;    
}
.patientContactClass {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    width: 100%;
}
.appointmentDetailsTxtClass {
    color: var(--Gray-1, #333);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.patientInfoLeftTxtClass {
    color: var(--grays-grey-3, #828282);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
}
.patientInfoRightTxtClass {
    color: var(--Gray-1, #333);
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
}
.underScoreLineDividerClass {
    border-bottom: 1px solid var(--grays-gray-6, #F2F2F2);
}
.pdfShowClass {
    display: flex;
    padding: 24px 20px;
    /* height: 132px; */
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    /* align-self: stretch; */
    border-radius: 4px;
    width: 100%;
    /* max-width: 350px; */
    border: 1px solid var(--grays-gray-5, #E0E0E0);
    overflow-x: auto;
}
</style>